
import {useParams} from "react-router-dom";
import Flow from "./components/Flow/Flow";
import Card from "./components/Card/Card";
import {isAndroid, isChrome, isIOS, isMobile} from 'react-device-detect';  

// @ts-ignore | Weird 
import mqtt from "mqtt/dist/mqtt";
import './assets/main.css';
import "./App.css";
import { useEffect, useLayoutEffect, useState } from "react";
import { cardLink, cardView, connectorMQTT, userDatas } from "@app/hoc/cache";
import Editor from "./components/UserInfo/editor";
import StaticFlow from "./components/Flow/StaticFlow";
import { Header } from "./components/UserInfo/header";
import { isFullScreen, isLocal } from "./components/Exam/containers/GameFullScreen";
import useResize from "./hook/useResize";
import { setSize } from "./constants/YMData";


const App = () =>{

    const { width : cWidth, height: cHeight } = useResize();
  // http://localhost:3006/flow/62beb9beb4a19a4c82d096ae
    const [goCard, setGoCard] = useState('');
    const [nextID, setNextID] = useState('');

    let { type,id,index } = useParams();
    //console.log( "connect:"+type+"/"+id+"/"+index)
    let smode = 0; // 1:static
    let scut = -1;
    if( type === 'seouli'){
      type = 'gen';
      id = '656ee085ddc5c298509694a0'; //'64d882f7c1013f031088cd57'; //'644e1dd36ea318031bbe14bb';
    }
    if( type === undefined && id === undefined && index === undefined){
      //type = 'flow';
      //id = '633a7aa5f42efd41782ab430';
      // for test 0502
      type = 'gen';
      //id = '655457861bb1a6d8242cc52f'; //'64d882f7c1013f031088cd57'; //'644e1dd36ea318031bbe14bb';
      id = '656ee085ddc5c298509694a0'; //for it 
    }
    if( type === 'cnt' ){ // for shortCut
      scut = parseInt(id as string);
      type = 'gen';
      if( scut === 1 )
        id = '637ca0850b9f2903201f81e7';
    }
    else if( type === 'id' ){ // for shortCut
      scut = parseInt(id as string)
/*
      type = 'flow';
      if( id === '11')
        id = '6353d348ee4f8f03254e1152';
      else if(id === '12')  
        id = '635f74a4ee4f8f03254e117b';
      else if(id === '13')  
        id = '6370657828dc490324bfd22e';
      else{
        type = 'flow';
        //id = '633a7aa5f42efd41782ab430';  
      }          
      */
      smode = 1;
    }

    //setSize();
    cardView({ ...cardView(), src: type === 'id'?1:0});

    useLayoutEffect(()=>{
      cardLink({ ...cardLink(),dev:window.location.host.indexOf('localhost') !== -1});
      //console.log('dev:'+window.location.host+'/'+cardLink().dev);
    },[]);
  
    useEffect(()=>{
      setNextID(goCard);
      setGoCard('');
    },[goCard])

    //<SpringLayout id={id} index={index}/>

    const initialize = () => {
      localStorage.setItem('type',type as string);
      const examIndex = localStorage.getItem('index') as string;

      let uidService = index;
      if( uidService === undefined )
        uidService = '';
      if( uidService !== '' ) //&& localStorage.getItem('index') !== '' )  
        localStorage.setItem('index', uidService as string);

      let resetUser =  false;
      if( examIndex !== '' && index === ''){
        resetUser = localStorage.getItem('fid') !== id ? true : false;
        if( resetUser )
          localStorage.setItem('fid',id as string);
      }

      userDatas({...userDatas(), scut: scut, cfid: (id as string), sfid:'642ffd5375ca7403156c8386'});


      //localStorage.clear();
      localStorage.setItem('exBdate', '2019-01-01');
      
      let exId = "";
      if( localStorage.getItem('exID') !== null && !resetUser)
        exId = localStorage.getItem('exID') as string;
      let userName = "";
      if( localStorage.getItem('exName') !== null && !resetUser)
        userName = localStorage.getItem('exName') as string;

      userDatas({...userDatas(), svcuid: exId, nickname: userName});
/*
      const clientId = 'mqttjs_' + Math.random().toString(16).substr(2, 8)
  
      var qos = 2;
      
      const options = {
        keepalive: 60,
        protocolId: 'MQTT',
        protocolVersion: 4,
        clean: true,
        reconnectPeriod: 1000,
        connectTimeout: 30 * 1000,
        will: {
          topic: 'WillMsg',
          payload: 'Connection Closed abnormally..!',
          qos: 2,
          retain: true
        },
        rejectUnauthorized: false
      }
      
      //console.log('connecting mqtt client')
      //- 1 ---------------------------------------------------------------------------
      //const mqtthost = 'ws://52.78.220.44:9001'; //wss://mqtt.liflo.io:443/liflo'
      const mqtthost = 'wss://mqtt.liflo.io:443/liflo';
      // @ts-ignore | Weird  
      const client = mqtt.connect(mqtthost, options)
      
      
      client.on('error', (err:any) => {
        //console.log('Connection error: ', err)
        client.end()
      })
      
      client.on('reconnect', () => {
        //console.log('Reconnecting...')
      })
      
      client.on('connect', () => {
        //console.log('Client connected:' + clientId)
        client.subscribe('/mbisq/cmd', { qos: 2 })
        client.subscribe('/mbisq_card/#', { qos: 2 })
      
        client.publish('/mbisq_client', 'ws connection ...!', { qos: 2, retain: true },
        (err:any) => { if(err) alert("서버와 연결이 끊어졌습니다. 다시 시작하세요.");})
      })
  
      client.on('message', function (topic:any, message:any) {
        // message is Buffer
        //console.log( "--mqtt--" + topic + ":"+ message.toString());
        //client.end()
      })
      connectorMQTT().client = client;

      //- 2 ---------------------------------------------------------------------------
      
      const mqtthost2 = 'wss://mq1.liflo.io'; //wss://mqtt.liflo.io:443/liflo'
      //const mqtthost2 = 'wss://ec2-52-78-220-44.ap-northeast-2.compute.amazonaws.com:443/';
      //const mqtthost2 = 'ws://52.78.220.44:9001'; //wss://mqtt.liflo.io:443/liflo'
      //const mqtthost = 'wss://mqtt.liflo.io:443/liflo';
      // @ts-ignore | Weird  
      const client2 = mqtt.connect(mqtthost2, options)
      
      
      client2.on('error', (err:any) => {
        //console.log('Connection error: ', err)
        client2.end()
      })
      
      client2.on('reconnect', () => {
        //console.log('Reconnecting...')
      })
      
      client2.on('connect', () => {
        //console.log('Client connected:' + clientId)
        client2.subscribe('/mbisq/cmd', { qos: 2 })
        client2.subscribe('/mbisq_card/#', { qos: 2 })
      
        client2.publish('/mbisq_client', 'ws connection ...!', { qos: 2, retain: true },
        (err:any) => { if(err) alert("서버와 연결이 끊어졌습니다. 다시 시작하세요.");})
      })
  
      client2.on('message', function (topic:any, message:any) {
        // message is Buffer
        //console.log( "--mqtt--" + topic + ":"+ message.toString());
        //client.end()
      })
      connectorMQTT().client2 = client2;
      */
    }

    initialize();
    
    useEffect(()=>{
      //setSize();
    },[])

    var clientWidth = function () {  return Math.max(window.innerWidth, document.documentElement.clientWidth);};
    var clientHeight = function () {  return Math.max(window.innerHeight, document.documentElement.clientHeight);};
    
    const getHeight = () => {
      if (!isMobile) {
        return window.innerHeight;
      }

      var usableOffset = 0;
      if (isIOS) {
        usableOffset = 20;
      } else if (isAndroid && !isChrome) {
        usableOffset = 1;
      }

      var isLandscape = window.innerWidth > window.innerHeight;
      var height;
      // on ios devices, this must use screen
      if( isIOS) {
        height = isLandscape ? window.screen.width : window.screen.height;
        height -= isLandscape ? 32 : 44;
        height += 1;
      } else {
        height = (isLandscape ? window.outerWidth : window.outerHeight) / (window.devicePixelRatio || 1);
      }
      return height - usableOffset;
    }

    return (
      <div className="App">
        { !isFullScreen() && <Header curr={'plan'} onGoCard={(id:any)=>setGoCard(id)}/>}
        { isLocal() && <Editor />}
        { type !== 'card' && smode === 0 && <Flow flow={ {id:id, type: type, meta:[]}} goCard={nextID}/>}
        { type !== 'card' && smode === 1 && <StaticFlow flow={ {id:id, type: type, meta:[]}} goCard={nextID}/>}
        { type === 'card' && <Card card={ {id:id, type: type, meta:[]}}/>}
        
      </div>
    );
}
// <Copyright />
export default App;
